<template>
  <div>
    <div class="question">
      <span class="question-title">Denunce di infortunio sul lavoro</span>
      <div class="number-row">
        <div class="number-row-question">
          <span>Numero di denunce di infortunio sul lavoro, escluse quelli in itinere, accolte {{ safetyReferenceYear }}</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input type="number" :min="0" :step="1" :value="surveyData.safety_nb_accident_at_work_requests_admitted_M" @input="setTextAsInteger('safety_nb_accident_at_work_requests_admitted_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input type="number" :min="0" :step="1" :value="surveyData.safety_nb_accident_at_work_requests_admitted_F" @input="setTextAsInteger('safety_nb_accident_at_work_requests_admitted_F', $event)">
            </label>
          </div>
        </div>
      </div>
      <div class="number-row" v-if="isAziendaPrivata || isAziendaPartecipata || isAmministrazioneStatale || isAziendaPubblica">
        <div class="number-row-question">
          <span>Numero di denunce di infortunio sul lavoro, escluse quelli in itinere, accolte  {{ safetyReferenceYear }} riguardanti addetti/e della categoria "Operaio"</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input type="number" :min="0" :step="1" :value="surveyData.safety_nb_accident_at_work_requests_admitted_labourer_M" @input="setTextAsInteger('safety_nb_accident_at_work_requests_admitted_labourer_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input type="number" :min="0" :step="1" :value="surveyData.safety_nb_accident_at_work_requests_admitted_labourer_F" @input="setTextAsInteger('safety_nb_accident_at_work_requests_admitted_labourer_F', $event)">
            </label>
          </div>
        </div>
      </div>
      <div class="number-row" v-if="isAziendaPrivata || isAziendaPartecipata || isAmministrazioneStatale || isAziendaPubblica">
        <div class="number-row-question">
          <span>Numero di denunce di infortunio sul lavoro, escluse quelli in itinere, accolte  {{ safetyReferenceYear }} riguardanti addetti/e della categoria "Impiegato"</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input type="number" :min="0" :step="1" :value="surveyData.safety_nb_accident_at_work_requests_admitted_office_worker_M" @input="setTextAsInteger('safety_nb_accident_at_work_requests_admitted_office_worker_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input type="number" :min="0" :step="1" :value="surveyData.safety_nb_accident_at_work_requests_admitted_office_worker_F" @input="setTextAsInteger('safety_nb_accident_at_work_requests_admitted_office_worker_F', $event)">
            </label>
          </div>
        </div>
      </div>
      <div class="number-row" v-if="isAziendaPrivata || isAziendaPartecipata || isAmministrazioneStatale || isAziendaPubblica">
        <div class="number-row-question">
          <span>Numero di denunce di infortunio sul lavoro, escluse quelli in itinere, accolte  {{ safetyReferenceYear }} riguardanti addetti/e della categoria "Quadro"</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input type="number" :min="0" :step="1" :value="surveyData.safety_nb_accident_at_work_requests_admitted_supervisor_M" @input="setTextAsInteger('safety_nb_accident_at_work_requests_admitted_supervisor_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input type="number" :min="0" :step="1" :value="surveyData.safety_nb_accident_at_work_requests_admitted_supervisor_F" @input="setTextAsInteger('safety_nb_accident_at_work_requests_admitted_supervisor_F', $event)">
            </label>
          </div>
        </div>
      </div>
      <div class="number-row" v-if="isUniversita">
        <div class="number-row-question">
          <span>
              Numero di denunce di infortunio sul lavoro, escluse quelli in itinere, accolte  {{ safetyReferenceYear }} riguardanti addetti/e del personale tecnico-amministrativo dell'area tecnica (B, C, D)
          </span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input type="number" :min="0" :step="1" :value="surveyData.safety_nb_accident_at_work_requests_admitted_technical_staff_M" @input="setTextAsInteger('safety_nb_accident_at_work_requests_admitted_technical_staff_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input type="number" :min="0" :step="1" :value="surveyData.safety_nb_accident_at_work_requests_admitted_technical_staff_F" @input="setTextAsInteger('safety_nb_accident_at_work_requests_admitted_technical_staff_F', $event)">
            </label>
          </div>
        </div>
      </div>
      <div class="number-row" v-if="isUniversita">
        <div class="number-row-question">
          <span>
              Numero di denunce di infortunio sul lavoro, escluse quelli in itinere, accolte  {{ safetyReferenceYear }} riguardanti addetti/e del personale tecnico-amministrativo dell'area amministrativa (B, C, D)
          </span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input type="number" :min="0" :step="1" :value="surveyData.safety_nb_accident_at_work_requests_admitted_administrative_staff_M" @input="setTextAsInteger('safety_nb_accident_at_work_requests_admitted_administrative_staff_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input type="number" :min="0" :step="1" :value="surveyData.safety_nb_accident_at_work_requests_admitted_administrative_staff_F" @input="setTextAsInteger('safety_nb_accident_at_work_requests_admitted_administrative_staff_F', $event)">
            </label>
          </div>
        </div>
      </div>
      <div class="number-row" v-if="isAziendaSanitaria">
        <div class="number-row-question">
          <span>
              Numero di denunce di infortunio sul lavoro, escluse quelli in itinere, accolte  {{ safetyReferenceYear }} riguardanti addetti/e del personale dirigente
          </span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input type="number" :min="0" :step="1" :value="surveyData.safety_nb_accident_at_work_requests_admitted_management_M" @input="setTextAsInteger('safety_nb_accident_at_work_requests_admitted_management_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input type="number" :min="0" :step="1" :value="surveyData.safety_nb_accident_at_work_requests_admitted_management_F" @input="setTextAsInteger('safety_nb_accident_at_work_requests_admitted_management_F', $event)">
            </label>
          </div>
        </div>
      </div>
      <div class="number-row" v-if="isAziendaSanitaria">
        <div class="number-row-question">
          <span>
              Numero di denunce di infortunio sul lavoro, escluse quelli in itinere, accolte  {{ safetyReferenceYear }} riguardanti addetti/e del personale comparto
          </span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input type="number" :min="0" :step="1" :value="surveyData.safety_nb_accident_at_work_requests_admitted_sector_M" @input="setTextAsInteger('safety_nb_accident_at_work_requests_admitted_sector_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input type="number" :min="0" :step="1" :value="surveyData.safety_nb_accident_at_work_requests_admitted_sector_F" @input="setTextAsInteger('safety_nb_accident_at_work_requests_admitted_sector_F', $event)">
            </label>
          </div>
        </div>
      </div>
      <div class="number-row" v-if="isNotUniversita && !isAziendaSanitaria">
        <div class="number-row-question">
          <span>
              Numero di denunce di infortunio sul lavoro, escluse quelli in itinere, accolte  {{ safetyReferenceYear }} riguardanti addetti/e della categoria "Dirigente"
          </span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input type="number" :min="0" :step="1" :value="surveyData.safety_nb_accident_at_work_requests_admitted_manager_M" @input="setTextAsInteger('safety_nb_accident_at_work_requests_admitted_manager_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input type="number" :min="0" :step="1" :value="surveyData.safety_nb_accident_at_work_requests_admitted_manager_F" @input="setTextAsInteger('safety_nb_accident_at_work_requests_admitted_manager_F', $event)">
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="question">
      <div class="number-row">
        <div class="number-row-question">
          <span class="question-title">Numero di denunce di infortuni in itinere accolte  {{ safetyReferenceYear }}</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input type="number" :min="0" :step="1" :value="surveyData.safety_nb_accident_at_work_requests_admitted_on_the_way_M" @input="setTextAsInteger('safety_nb_accident_at_work_requests_admitted_on_the_way_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input type="number" :min="0" :step="1" :value="surveyData.safety_nb_accident_at_work_requests_admitted_on_the_way_F" @input="setTextAsInteger('safety_nb_accident_at_work_requests_admitted_on_the_way_F', $event)">
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="question">
      <div class="number-row">
        <div class="number-row-question">
          <span class="question-title">Numero di denunce di malattie professionali inoltrate all'Inail negli ultimi 5 anni</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input type="number" :min="0" :step="1" :value="surveyData.safety_nb_occupational_disease_requests_submitted_M" @input="setTextAsInteger('safety_nb_occupational_disease_requests_submitted_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input type="number" :min="0" :step="1" :value="surveyData.safety_nb_occupational_disease_requests_submitted_F" @input="setTextAsInteger('safety_nb_occupational_disease_requests_submitted_F', $event)">
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="question">
      <div class="number-row">
        <div class="number-row-question">
          <span class="question-title">Numero di denunce di malattie professionali respinte negli ultimi 5 anni</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input type="number" :min="0" :step="1" :value="surveyData.safety_nb_occupational_disease_requests_rejected_M" @input="setTextAsInteger('safety_nb_occupational_disease_requests_rejected_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input type="number" :min="0" :step="1" :value="surveyData.safety_nb_occupational_disease_requests_rejected_F" @input="setTextAsInteger('safety_nb_occupational_disease_requests_rejected_F', $event)">
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="question">
      <div class="number-row">
        <div class="number-row-question">
          <span class="question-title">Numero di denunce per <span data-explained-by-term @click="showTerm('mobbing')">mobbing</span> negli ultimi 5 anni</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input type="number" :min="0" :step="1" :value="surveyData.safety_nb_harassment_complaint_submitted_M" @input="setTextAsInteger('safety_nb_harassment_complaint_submitted_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input type="number" :min="0" :step="1" :value="surveyData.safety_nb_harassment_complaint_submitted_F" @input="setTextAsInteger('safety_nb_harassment_complaint_submitted_F', $event)">
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="question">
      <div class="number-row">
        <div class="number-row-question">
          <span class="question-title">Numero di denunce per <span data-explained-by-term @click="showTerm('mobbing')">mobbing</span> respinte negli ultimi 5 anni</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input type="number" :min="0" :step="1" :value="surveyData.safety_nb_harassment_complaint_rejected_M" @input="setTextAsInteger('safety_nb_harassment_complaint_rejected_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input type="number" :min="0" :step="1" :value="surveyData.safety_nb_harassment_complaint_rejected_F" @input="setTextAsInteger('safety_nb_harassment_complaint_rejected_F', $event)">
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="question">
      <div class="number-row">
        <div class="number-row-question">
          <span class="question-title">Numero di denunce per <span data-explained-by-term @click="showTerm('molestie-sul-lavoro')">molestie</span> negli ultimi 5 anni</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input type="number" :min="0" :step="1" :value="surveyData.safety_nb_sexual_harassment_complaint_submitted_M" @input="setTextAsInteger('safety_nb_sexual_harassment_complaint_submitted_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input type="number" :min="0" :step="1" :value="surveyData.safety_nb_sexual_harassment_complaint_submitted_F" @input="setTextAsInteger('safety_nb_sexual_harassment_complaint_submitted_F', $event)">
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="question">
        <span class="question-title" v-tooltip="'In presenza di una valutazione quantitativa dei rischi presenti in azienda, ai fini di una valutazione in ottica di genere più accurata, tenere in considerazione le indicazioni associate ad ogni tipo di rischio'">
          Per ciascun rischio presente nell'azienda\ente, vi preghiamo di specificare
        </span>
      <table class="hazard-workers">
        <thead>
        <tr>
          <th>Rischio</th>
          <th>
            <span v-tooltip="'Ci si riferisce al numero di addetti/e e non di contratti, nell\'anno solare di riferimento, indipendentemente dal tempo di esposizione.'">
              Numero di addetti/e esposti/e
            </span>
          </th>
          <th>
            <span v-tooltip="'Ci si riferisce al numero di addetti/e, non al numero di prescrizioni.'">
              Numero di idonei/e con prescrizioni o limitazioni (temporanee o permanenti)
            </span>
          </th>
          <th>
            <span v-tooltip="'Ci si riferisce al numero di addetti/e, non al numero di prescrizioni.'">
              Numero di addetti/e con inidoneità (temporanee o permanenti)
            </span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="hazard in [
          { id: 'noise', name: 'Rumore', tooltip: 'Considerare i/le lavoratori/ici esposti/e a valori superiori a LEX,8h = 80 dB(A)' },
          { id: 'ionizing_radiation', name: 'Radiazioni ionizzanti', tooltip: 'Considerare i/le lavoratori/ici classificati in Categoria A e Categoria B dall\'Esperto Qualificato' },
          { id: 'chemical', name: 'Rischi chimici', tooltip: 'Considerare i/le lavoratori/ici la cui esposizione a rischio chimico è risultata \'Non irrilevante per la salute e/o non basso per la sicurezza\'' },
          { id: 'biological', name: 'Rischi biologici' },
          { id: 'manual_handling_of_loads', name: 'Movimentazione manuale dei carichi', tooltip: 'Considerare i/le lavoratori/ici che nella valutazione dei rischi risultino in fascia gialla o comunque ad un livello superiore rispetto alla soglia di riferimento.' },
          { id: 'repetitive_movements', name: 'Movimenti ripetitivi', tooltip: 'Considerare i/le lavoratori/ici che nella valutazione dei rischi risultino in fascia gialla o comunque ad un livello superiore rispetto alla soglia di riferimento.' },
          { id: 'night_work', name: 'Lavoro notturno' },
          { id: 'shift_work', name: 'Turni' },
          { id: 'work_related_stress', name: 'Stress lavoro correlato', tooltip: 'Considerare i/le lavoratori/ici che nella valutazione dei rischi risultino in fascia gialla o comunque ad un livello superiore rispetto alla soglia di riferimento.' },
          { id: 'microclimate', name: 'Microclima' },
          { id: 'vibration', name: 'Vibrazioni', tooltip: 'Considerare i/le lavoratori/ici esposti/e a valori superiori a Livello d\'azione giornaliero di esposizione A(8) = 2,5 m/s2, per vibrazioni trasmesse al sistema mano/braccio e A(8) = 0,5 m/s2 per vibrazioni trasmesse al Corpo Intero' },
          { id: 'unfair_posture', name: 'Posture incongrue' },
        ]" :data-hazard="hazard.id" :key="hazard.id">
          <th class="hazard">
            <span v-if="hazard.tooltip" v-tooltip="hazard.tooltip">{{ hazard.name }}</span>
            <span v-else>{{ hazard.name }}</span>
          </th>
          <td>
            <div class="d-flex">
              <label>
                <span>M</span>
                <input type="number" :min="0" :step="1" :value="surveyData['safety_hazard_' + hazard.id + '_nb_workers_exposed_M']" @input="setTextAsInteger('safety_hazard_' + hazard.id + '_nb_workers_exposed_M', $event)">
              </label>
              <label>
                <span>F</span>
                <input type="number" :min="0" :step="1" :value="surveyData['safety_hazard_' + hazard.id + '_nb_workers_exposed_F']" @input="setTextAsInteger('safety_hazard_' + hazard.id + '_nb_workers_exposed_F', $event)">
              </label>
            </div>
          </td>
          <td>
            <div class="d-flex">
              <label>
                <span>M</span>
                <input type="number" :step="1" :min="0" :max="surveyData['safety_hazard_' + hazard.id + '_nb_workers_exposed_M']" :disabled="!(surveyData['safety_hazard_' + hazard.id + '_nb_workers_exposed_M'] > 0)" :value="surveyData['safety_hazard_' + hazard.id + '_nb_workers_with_limitation_M']" @input="setTextAsInteger('safety_hazard_' + hazard.id + '_nb_workers_with_limitation_M', $event)">
              </label>
              <label>
                <span>F</span>
                <input type="number" :step="1" :min="0" :max="surveyData['safety_hazard_' + hazard.id + '_nb_workers_exposed_F']" :disabled="!(surveyData['safety_hazard_' + hazard.id + '_nb_workers_exposed_F'] > 0)" :value="surveyData['safety_hazard_' + hazard.id + '_nb_workers_with_limitation_F']" @input="setTextAsInteger('safety_hazard_' + hazard.id + '_nb_workers_with_limitation_F', $event)">
              </label>
            </div>
          </td>
          <td>
            <div class="d-flex">
              <label>
                <span>M</span>
                <input type="number" :step="1" :min="0" :max="surveyData['safety_hazard_' + hazard.id + '_nb_workers_exposed_M']" :disabled="!(surveyData['safety_hazard_' + hazard.id + '_nb_workers_exposed_M'] > 0)" :value="surveyData['safety_hazard_' + hazard.id + '_nb_workers_unfit_M']" @input="setTextAsInteger('safety_hazard_' + hazard.id + '_nb_workers_unfit_M', $event)">
              </label>
              <label>
                <span>F</span>
                <input type="number" :step="1" :min="0" :max="surveyData['safety_hazard_' + hazard.id + '_nb_workers_exposed_F']" :disabled="!(surveyData['safety_hazard_' + hazard.id + '_nb_workers_exposed_F'] > 0)" :value="surveyData['safety_hazard_' + hazard.id + '_nb_workers_unfit_F']" @input="setTextAsInteger('safety_hazard_' + hazard.id + '_nb_workers_unfit_F', $event)">
              </label>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="question">
      <span class="question-title">
        In quali dei seguenti rischi sensibili al genere presenti nell'azienda\ente avete provveduto ad effettuare una specifica valutazione dei rischi?
      </span>
      <table class="hazard-assessments">
        <tbody>
        <tr>
          <td class="hazard">
            <label>
              <input type="checkbox" :value="1" :checked="1 === surveyData.safety_is_hazard_there_manual_handling_of_loads" @change="setCheckbox('safety_is_hazard_there_manual_handling_of_loads', $event)">
              <span>
                Rischio ergonomico: movimentazione manuale dei carichi
              </span>
            </label>
          </td>
          <td>
            <div v-if="1 === surveyData.safety_is_hazard_there_manual_handling_of_loads">
              <fieldset class="checkbox-set">
                <span class="question-title" v-tooltip="'In caso siano stati utilizzati più metodi, esprimere una percentuale approssimativa del livello di impiego relativo dei singoli metodi (es. azienda con più reparti di cui la maggior parte valutati con NIOSH, 80%, ed una piccola parte valutata con OWAS, 20%).'">
                  In che modo è valutato il rischio ergonomico di movimentazione manuale dei carichi?
                </span>
                <div class="assessment">
                  <label>
                    <input type="checkbox" :value="1" :checked="1 === surveyData.safety_hazard_manual_handling_of_loads_assessment_niosh" @change="setCheckbox('safety_hazard_manual_handling_of_loads_assessment_niosh', $event)">
                    <span>Metodo NIOSH</span>
                  </label>
                  <label class="assessment-percentage" v-if="1 === surveyData.safety_hazard_manual_handling_of_loads_assessment_niosh && manualHandlingOfLoadsSelectedAssessments > 1">
                    <input type="number" min="10" :max="100" :step="10" :value="surveyData.safety_hazard_manual_handling_of_loads_assessment_niosh_percentage" @input="setTextAsInteger('safety_hazard_manual_handling_of_loads_assessment_niosh_percentage', $event)">
                    <span>{{ surveyData.safety_hazard_manual_handling_of_loads_assessment_niosh_percentage }}%</span>
                  </label>
                  <div class="extra indented" v-if="1 === surveyData.safety_hazard_manual_handling_of_loads_assessment_niosh">
                    <fieldset class="radio-set">
                      <label>
                        <input type="radio" :value="1" :checked="1 === surveyData.safety_hazard_manual_handling_of_loads_assessment_niosh_how" @change="setRadio('safety_hazard_manual_handling_of_loads_assessment_niosh_how', $event)">
                        <span>Con pesi limite differenziati per sesso ed età (ISO 11228 parte 1)</span>
                      </label>
                      <label>
                        <input type="radio" :value="2" :checked="2 === surveyData.safety_hazard_manual_handling_of_loads_assessment_niosh_how" @change="setRadio('safety_hazard_manual_handling_of_loads_assessment_niosh_how', $event)">
                        <span>Con pesi limite differenziati per sesso ed età (valori originali USA: 23 Kg cautelativo per il 99% degli uomini ed il 75% delle donne)</span>
                      </label>
                    </fieldset>
                  </div>
                </div>
                <div class="assessment">
                  <label>
                    <input type="checkbox" :value="1" :checked="1 === surveyData.safety_hazard_manual_handling_of_loads_assessment_owas" @change="setCheckbox('safety_hazard_manual_handling_of_loads_assessment_owas', $event)">
                    <span>Metodo OWAS</span>
                  </label>
                  <label class="assessment-percentage" v-if="1 === surveyData.safety_hazard_manual_handling_of_loads_assessment_owas && manualHandlingOfLoadsSelectedAssessments > 1">
                    <input type="number" min="10" :max="100" :step="10" :value="surveyData.safety_hazard_manual_handling_of_loads_assessment_owas_percentage" @input="setTextAsInteger('safety_hazard_manual_handling_of_loads_assessment_owas_percentage', $event)">
                    <span>%</span>
                  </label>
                  <div class="extra indented" v-if="1 === surveyData.safety_hazard_manual_handling_of_loads_assessment_owas">
                    <fieldset class="radio-set">
                      <label>
                        <input type="radio" :value="1" :checked="1 === surveyData.safety_hazard_manual_handling_of_loads_assessment_owas_how" @change="setRadio('safety_hazard_manual_handling_of_loads_assessment_owas_how', $event)">
                        <span>Valutazione effettuata dall'osservazione della postura di gruppi di lavoratori/rici con rappresentanza di genere, là dove possibile</span>
                      </label>
                      <label>
                        <input type="radio" :value="2" :checked="2 === surveyData.safety_hazard_manual_handling_of_loads_assessment_owas_how" @change="setRadio('safety_hazard_manual_handling_of_loads_assessment_owas_how', $event)">
                        <span>Non è possibile effettuare una valutazione su un campione misto in quanto è presente un solo genere esposto a MMC</span>
                      </label>
                      <label>
                        <input type="radio" :value="3" :checked="3 === surveyData.safety_hazard_manual_handling_of_loads_assessment_owas_how" @change="setRadio('safety_hazard_manual_handling_of_loads_assessment_owas_how', $event)">
                        <span>Non è stata effettuata una valutazione basata sull'osservazione di un campione misto</span>
                      </label>
                    </fieldset>
                  </div>
                </div>
                <div class="assessment">
                  <label>
                    <input type="checkbox" :value="1" :checked="1 === surveyData.safety_hazard_manual_handling_of_loads_assessment_mcg" @change="setCheckbox('safety_hazard_manual_handling_of_loads_assessment_mcg', $event)">
                    <span>Metodo MCG</span>
                  </label>
                  <label class="assessment-percentage" v-if="surveyData.safety_hazard_manual_handling_of_loads_assessment_mcg && manualHandlingOfLoadsSelectedAssessments > 1">
                    <input type="number" min="10" :max="100" :step="10" :value="surveyData.safety_hazard_manual_handling_of_loads_assessment_mcg_percentage" @input="setTextAsInteger('safety_hazard_manual_handling_of_loads_assessment_mcg_percentage', $event)">
                    <span>%</span>
                  </label>
                  <div class="extra indented" v-if="surveyData.safety_hazard_manual_handling_of_loads_assessment_mcg">
                    <fieldset class="radio-set">
                      <label>
                        <input type="radio" :value="1" :checked="1 === surveyData.safety_hazard_manual_handling_of_loads_assessment_mcg_how" @change="setRadio('safety_hazard_manual_handling_of_loads_assessment_mcg_how', $event)">
                        <span>Valutazione effettuata dall'osservazione della postura di gruppi di lavoratori/rici con rappresentanza di genere</span>
                      </label>
                      <label>
                        <input type="radio" :value="2" :checked="2 === surveyData.safety_hazard_manual_handling_of_loads_assessment_mcg_how" @change="setRadio('safety_hazard_manual_handling_of_loads_assessment_mcg_how', $event)">
                        <span>Non è possibile effettuare una valutazione su un campione misto in quanto è presente un solo genere esposto a MMC</span>
                      </label>
                      <label>
                        <input type="radio" :value="3" :checked="3 === surveyData.safety_hazard_manual_handling_of_loads_assessment_mcg_how" @change="setRadio('safety_hazard_manual_handling_of_loads_assessment_mcg_how', $event)">
                        <span>Non è stata effettuata una valutazione basata sull'osservazione di un campione misto</span>
                      </label>
                    </fieldset>
                  </div>
                </div>
                <div class="assessment">
                  <label>
                    <input type="checkbox" :value="1" :checked="1 === surveyData.safety_hazard_manual_handling_of_loads_assessment_mac" @change="setCheckbox('safety_hazard_manual_handling_of_loads_assessment_mac', $event)">
                    <span>Metodo MAC</span>
                  </label>
                  <label class="assessment-percentage" v-if="1 === surveyData.safety_hazard_manual_handling_of_loads_assessment_mac && manualHandlingOfLoadsSelectedAssessments > 1">
                    <input type="number" min="10" :max="100" :step="10" :value="surveyData.safety_hazard_manual_handling_of_loads_assessment_mac_percentage" @input="setTextAsInteger('safety_hazard_manual_handling_of_loads_assessment_mac_percentage', $event)">
                    <span>%</span>
                  </label>
                  <div class="extra indented" v-if="1 === surveyData.safety_hazard_manual_handling_of_loads_assessment_mac">
                    <fieldset class="radio-set">
                      <label>
                        <input type="radio" :value="1" :checked="1 === surveyData.safety_hazard_manual_handling_of_loads_assessment_mac_how" @change="setRadio('safety_hazard_manual_handling_of_loads_assessment_mac_how', $event)">
                        <span>Valutazione effettuata dall'osservazione della postura di gruppi di lavoratori/rici con rappresentanza di genere</span>
                      </label>
                      <label>
                        <input type="radio" :value="2" :checked="2 === surveyData.safety_hazard_manual_handling_of_loads_assessment_mac_how" @change="setRadio('safety_hazard_manual_handling_of_loads_assessment_mac_how', $event)">
                        <span>Non è possibile effettuare una valutazione su un campione misto in quanto è presente un solo genere esposto a MMC</span>
                      </label>
                      <label>
                        <input type="radio" :value="3" :checked="3 === surveyData.safety_hazard_manual_handling_of_loads_assessment_mac_how" @change="setRadio('safety_hazard_manual_handling_of_loads_assessment_mac_how', $event)">
                        <span>Non è stata effettuata una valutazione basata sull'osservazione di un campione misto</span>
                      </label>
                    </fieldset>
                  </div>
                </div>
                <div class="assessment">
                  <label>
                    <input type="checkbox" :value="1" :checked="1 === surveyData.safety_hazard_manual_handling_of_loads_assessment_other" @change="setCheckbox('safety_hazard_manual_handling_of_loads_assessment_other', $event)">
                    <span>Altro</span>
                  </label>
                  <label class="assessment-percentage" v-if="1 === surveyData.safety_hazard_manual_handling_of_loads_assessment_other && manualHandlingOfLoadsSelectedAssessments > 1">
                    <input type="number" min="10" :max="100" :step="10" :value="surveyData.safety_hazard_manual_handling_of_loads_assessment_other_percentage" @input="setTextAsInteger('safety_hazard_manual_handling_of_loads_assessment_other_percentage', $event)">
                    <span>%</span>
                  </label>
                  <div class="extra indented" v-if="1 === surveyData.safety_hazard_manual_handling_of_loads_assessment_other">
                    <label>Specificare: <input type="text" :value="surveyData.safety_hazard_manual_handling_of_loads_assessment_other_what" @input="setText('safety_hazard_manual_handling_of_loads_assessment_other_what', $event)"></label>
                    <fieldset class="radio-set">
                      <label>
                        <input type="radio" :value="1" :checked="1 === surveyData.safety_hazard_manual_handling_of_loads_assessment_other_how" @change="setRadio('safety_hazard_manual_handling_of_loads_assessment_other_how', $event)">
                        <span>Valutazione effettuata dall'osservazione della postura di gruppi di lavoratori/rici con rappresentanza di genere</span>
                      </label>
                      <label>
                        <input type="radio" :value="2" :checked="2 === surveyData.safety_hazard_manual_handling_of_loads_assessment_other_how" @change="setRadio('safety_hazard_manual_handling_of_loads_assessment_other_how', $event)">
                        <span>Non è possibile effettuare una valutazione su un campione misto in quanto è presente un solo genere esposto a MMC</span>
                      </label>
                      <label>
                        <input type="radio" :value="3" :checked="3 === surveyData.safety_hazard_manual_handling_of_loads_assessment_other_how" @change="setRadio('safety_hazard_manual_handling_of_loads_assessment_other_how', $event)">
                        <span>Non è stata effettuata una valutazione basata sull'osservazione di un campione misto</span>
                      </label>
                    </fieldset>
                  </div>
                </div>
              </fieldset>
            </div>
          </td>
        </tr>
        <tr>
          <td class="hazard">
            <label>
              <input type="checkbox" :value="1" :checked="1 === surveyData.safety_is_hazard_there_pushing_and_pulling" @change="setCheckbox('safety_is_hazard_there_pushing_and_pulling', $event)">
              <span>
                Rischio ergonomico: traino e spinta
              </span>
            </label>
          </td>
          <td>
            <div v-if="1 === surveyData.safety_is_hazard_there_pushing_and_pulling">
              <span class="question-title" v-tooltip="'In caso siano stati utilizzati più metodi, esprimere una percentuale approssimativa del livello di impiego relativo dei singoli metodi (es. azienda con più reparti di cui la maggior parte valutati con Snook-Ciriello, 80%, ed una piccola parte valutata con altri metodi, 20%).'">
                In che modo è valutato il rischio ergonomico di traino e spinta?
              </span>
              <fieldset class="checkbox-set">
                <div class="assessment">
                  <label>
                    <input type="checkbox" :value="1" :checked="1 === surveyData.safety_hazard_pushing_and_pulling_assessment_snook_ciriello" @change="setCheckbox('safety_hazard_pushing_and_pulling_assessment_snook_ciriello', $event)">
                    <span>Metodo Snook-Ciriello</span>
                  </label>
                  <label class="assessment-percentage" v-if="1 === surveyData.safety_hazard_pushing_and_pulling_assessment_snook_ciriello && pushAndPullingSelectedAssessments > 1">
                    <input type="number" min="10" :max="100" :step="10" :value="surveyData.safety_hazard_pushing_and_pulling_assessment_snook_ciriello_percentage" @input="setTextAsInteger('safety_hazard_pushing_and_pulling_assessment_snook_ciriello_percentage', $event)">
                    <span>%</span>
                  </label>
                </div>
                <div class="assessment">
                  <label>
                    <input type="checkbox" :value="1" :checked="1 === surveyData.safety_hazard_pushing_and_pulling_assessment_other" @change="setCheckbox('safety_hazard_pushing_and_pulling_assessment_other', $event)">
                    <span>Altro</span>
                  </label>
                  <label class="assessment-percentage" v-if="1 === surveyData.safety_hazard_pushing_and_pulling_assessment_other && pushAndPullingSelectedAssessments > 1">
                    <input type="number" min="10" :max="100" :step="10" :value="surveyData.safety_hazard_pushing_and_pulling_assessment_other_percentage" @input="setTextAsInteger('safety_hazard_pushing_and_pulling_assessment_other_percentage', $event)">
                    <span>%</span>
                  </label>
                  <div class="extra" v-if="1 === surveyData.safety_hazard_pushing_and_pulling_assessment_other">
                    <label>Specificare: <input type="text" :value="surveyData.safety_hazard_pushing_and_pulling_assessment_other_what" @input="setText('safety_hazard_pushing_and_pulling_assessment_other_what', $event)"></label>
                  </div>
                </div>
              </fieldset>
            </div>
          </td>
        </tr>
        <tr>
          <td class="hazard">
            <label>
              <input type="checkbox" :value="1" :checked="1 === surveyData.safety_is_hazard_there_manual_handling_of_patients" @change="setCheckbox('safety_is_hazard_there_manual_handling_of_patients', $event)">
              <span>Rischio ergonomico: movimentazione manuale pazienti</span>
            </label>
          </td>
          <td>
            <div v-if="1 === surveyData.safety_is_hazard_there_manual_handling_of_patients">
              <span class="question-title" v-tooltip="'In caso siano stati utilizzati più metodi, esprimere una percentuale approssimativa del livello di impiego relativo dei singoli metodi (es. azienda con più reparti di cui la maggior parte valutati con MAPO, 80%, ed una piccola parte valutata con SpoSO, 20%).'">
                In che modo è valutato il rischio ergonomico di movimentazione manuale dei pazienti?
              </span>
              <fieldset class="checkbox-set">
                <div class="assessment">
                  <label>
                    <input type="checkbox" :value="1" :checked="1 === surveyData.safety_hazard_manual_handling_of_patients_assessment_mapo" @change="setCheckbox('safety_hazard_manual_handling_of_patients_assessment_mapo', $event)">
                    <span>Metodo MAPO</span>
                  </label>
                  <label class="assessment-percentage" v-if="1 === surveyData.safety_hazard_manual_handling_of_patients_assessment_mapo && manualHandlingOfPatientsSelectedAssessments > 1">
                    <input type="number" min="10" :max="100" :step="10" :value="surveyData.safety_hazard_manual_handling_of_patients_assessment_mapo_percentage" @input="setTextAsInteger('safety_hazard_manual_handling_of_patients_assessment_mapo_percentage', $event)">
                    <span>%</span>
                  </label>
                </div>
                <div class="assessment">
                  <label>
                    <input type="checkbox" :value="1" :checked="1 === surveyData.safety_hazard_manual_handling_of_patients_assessment_sposo" @change="setCheckbox('safety_hazard_manual_handling_of_patients_assessment_sposo', $event)">
                    <span>Metodo SpoSO</span>
                  </label>
                  <label class="assessment-percentage" v-if="1 === surveyData.safety_hazard_manual_handling_of_patients_assessment_sposo && manualHandlingOfPatientsSelectedAssessments > 1">
                    <input type="number" min="10" :max="100" :step="10" :value="surveyData.safety_hazard_manual_handling_of_patients_assessment_sposo_percentage" @input="setTextAsInteger('safety_hazard_manual_handling_of_patients_assessment_sposo_percentage', $event)">
                    <span>%</span>
                  </label>
                </div>
                <div class="assessment">
                  <label>
                    <input type="checkbox" :value="1" :checked="1 === surveyData.safety_hazard_manual_handling_of_patients_assessment_other" @change="setCheckbox('safety_hazard_manual_handling_of_patients_assessment_other', $event)">
                    <span>Altro</span>
                  </label>
                  <label class="assessment-percentage" v-if="1 === surveyData.safety_hazard_manual_handling_of_patients_assessment_other && manualHandlingOfPatientsSelectedAssessments > 1">
                    <input type="number" min="10" :max="100" :step="10" :value="surveyData.safety_hazard_manual_handling_of_patients_assessment_other_percentage" @input="setTextAsInteger('safety_hazard_manual_handling_of_patients_assessment_other_percentage', $event)">
                    <span>%</span>
                  </label>
                  <div class="extra indented" v-if="1 === surveyData.safety_hazard_manual_handling_of_patients_assessment_other">
                    <label>Specificare: <input type="text" :value="surveyData.safety_hazard_manual_handling_of_patients_assessment_other_what" @input="setText('safety_hazard_manual_handling_of_patients_assessment_other_what', $event)"></label>
                  </div>
                </div>
              </fieldset>
            </div>
          </td>
        </tr>
        <tr>
          <td class="hazard">
            <label>
              <input type="checkbox" :value="1" :checked="1 === surveyData.safety_is_hazard_there_repetitive_movements" @change="setCheckbox('safety_is_hazard_there_repetitive_movements', $event)">
              <span>Movimenti ripetitivi</span>
            </label>
          </td>
          <td>
            <div v-if="1 === surveyData.safety_is_hazard_there_repetitive_movements">
              <span class="question-title" v-tooltip="'In caso siano stati utilizzati più metodi, esprimere una percentuale approssimativa del livello di impiego relativo dei singoli metodi (es. azienda con più reparti di cui la maggior parte valutati con OCRA, 80%, ed una piccola parte valutata con RULA, 20%).'">
                In che modo è valutato il rischio da movimenti ripetitivi?
              </span>
              <fieldset class="checkbox-set">
                <div class="assessment">
                  <label>
                    <input type="checkbox" :value="1" :checked="1 === surveyData.safety_hazard_repetitive_movements_assessment_ocra" @change="setCheckbox('safety_hazard_repetitive_movements_assessment_ocra', $event)">
                    <span>OCRA index / check list (ISO 11228 parte 3)</span>
                  </label>
                  <label class="assessment-percentage" v-if="1 === surveyData.safety_hazard_repetitive_movements_assessment_ocra && repetitiveMovementsSelectedAssessments > 1">
                    <input type="number" min="10" :max="100" :step="10" :value="surveyData.safety_hazard_repetitive_movements_assessment_ocra_percentage" @input="setTextAsInteger('safety_hazard_repetitive_movements_assessment_ocra_percentage', $event)">
                    <span>%</span>
                  </label>
                  <div class="extra indented" v-if="1 === surveyData.safety_hazard_repetitive_movements_assessment_ocra">
                    <fieldset class="radio-set">
                      <label>
                        <input type="radio" :value="1" :checked="1 === surveyData.safety_hazard_repetitive_movements_assessment_ocra_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_ocra_how', $event)">
                        <span>Indice calcolato in modo differenziato su lavoratori e lavoratrici</span>
                      </label>
                      <label>
                        <input type="radio" :value="2" :checked="2 === surveyData.safety_hazard_repetitive_movements_assessment_ocra_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_ocra_how', $event)">
                        <span>Valutazione effettuata sull'osservazione di una popolazione mista di lavoratori e lavoratrici, tenendo conto di eventuali differenze nei parametri postura e forza, là dove possibile</span>
                      </label>
                      <label>
                        <input type="radio" :value="3" :checked="3 === surveyData.safety_hazard_repetitive_movements_assessment_ocra_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_ocra_how', $event)">
                        <span>Non è possibile effettuare una valutazione su un campione misto in quanto è presente un solo genere esposto a movimenti ripetitivi</span>
                      </label>
                      <label>
                        <input type="radio" :value="4" :checked="4 === surveyData.safety_hazard_repetitive_movements_assessment_ocra_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_ocra_how', $event)">
                        <span>Non è stata effettuata una valutazione basata sull'osservazione di un campione misto</span>
                      </label>
                    </fieldset>
                  </div>
                </div>
                <div class="assessment">
                  <label>
                    <input type="checkbox" :value="1" :checked="1 === surveyData.safety_hazard_repetitive_movements_assessment_rula" @change="setCheckbox('safety_hazard_repetitive_movements_assessment_rula', $event)">
                    <span>Metodo RULA</span>
                  </label>
                  <label class="assessment-percentage" v-if="1 === surveyData.safety_hazard_repetitive_movements_assessment_rula && repetitiveMovementsSelectedAssessments > 1">
                    <input type="number" min="10" :max="100" :step="10" :value="surveyData.safety_hazard_repetitive_movements_assessment_rula_percentage" @input="setTextAsInteger('safety_hazard_repetitive_movements_assessment_rula_percentage', $event)">
                    <span>%</span>
                  </label>
                  <div class="extra indented" v-if="1 === surveyData.safety_hazard_repetitive_movements_assessment_rula">
                    <fieldset class="radio-set">
                      <label>
                        <input type="radio" :value="1" :checked="1 === surveyData.safety_hazard_repetitive_movements_assessment_rula_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_rula_how', $event)">
                        <span>Indice calcolato in modo differenziato su lavoratori e lavoratrici</span>
                      </label>
                      <label>
                        <input type="radio" :value="2" :checked="2 === surveyData.safety_hazard_repetitive_movements_assessment_rula_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_rula_how', $event)">
                        <span>Valutazione effettuata sull'osservazione di una popolazione mista di lavoratori e lavoratrici, là dove possibile</span>
                      </label>
                      <label>
                        <input type="radio" :value="3" :checked="3 === surveyData.safety_hazard_repetitive_movements_assessment_rula_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_rula_how', $event)">
                        <span>Non è possibile effettuare una valutazione su un campione misto in quanto è presente un solo genere esposto a movimenti ripetitivi</span>
                      </label>
                      <label>
                        <input type="radio" :value="4" :checked="4 === surveyData.safety_hazard_repetitive_movements_assessment_rula_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_rula_how', $event)">
                        <span>Non è stata effettuata una valutazione basata sull'osservazione di un campione misto</span>
                      </label>
                    </fieldset>
                  </div>
                </div>
                <div class="assessment">
                  <label>
                    <input type="checkbox" :value="1" :checked="1 === surveyData.safety_hazard_repetitive_movements_assessment_osha" @change="setCheckbox('safety_hazard_repetitive_movements_assessment_osha', $event)">
                    <span>Metodo OSHA</span>
                  </label>
                  <label class="assessment-percentage" v-if="1 === surveyData.safety_hazard_repetitive_movements_assessment_osha && repetitiveMovementsSelectedAssessments > 1">
                    <input type="number" min="10" :max="100" :step="10" :value="surveyData.safety_hazard_repetitive_movements_assessment_osha_percentage" @input="setTextAsInteger('safety_hazard_repetitive_movements_assessment_osha_percentage', $event)">
                    <span>%</span>
                  </label>
                  <div class="extra indented" v-if="1 === surveyData.safety_hazard_repetitive_movements_assessment_osha">
                    <fieldset class="radio-set">
                      <label>
                        <input type="radio" :value="1" :checked="1 === surveyData.safety_hazard_repetitive_movements_assessment_osha_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_osha_how', $event)">
                        <span>Indice calcolato in modo differenziato su lavoratori e lavoratrici</span>
                      </label>
                      <label>
                        <input type="radio" :value="2" :checked="2 === surveyData.safety_hazard_repetitive_movements_assessment_osha_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_osha_how', $event)">
                        <span>Valutazione effettuata sull'osservazione di una popolazione mista di lavoratori e lavoratrici, là dove possibile</span>
                      </label>
                      <label>
                        <input type="radio" :value="3" :checked="3 === surveyData.safety_hazard_repetitive_movements_assessment_osha_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_osha_how', $event)">
                        <span>Non è possibile effettuare una valutazione su un campione misto in quanto è presente un solo genere esposto a movimenti ripetitivi</span>
                      </label>
                      <label>
                        <input type="radio" :value="4" :checked="4 === surveyData.safety_hazard_repetitive_movements_assessment_osha_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_osha_how', $event)">
                        <span>Non è stata effettuata una valutazione basata sull'osservazione di un campione misto</span>
                      </label>
                    </fieldset>
                  </div>
                </div>
                <div class="assessment">
                  <label>
                    <input type="checkbox" :value="1" :checked="1 === surveyData.safety_hazard_repetitive_movements_assessment_reba" @change="setCheckbox('safety_hazard_repetitive_movements_assessment_reba', $event)">
                    <span>Metodo REBA</span>
                  </label>
                  <label class="assessment-percentage" v-if="1 === surveyData.safety_hazard_repetitive_movements_assessment_reba && repetitiveMovementsSelectedAssessments > 1">
                    <input type="number" min="10" :max="100" :step="10" :value="surveyData.safety_hazard_repetitive_movements_assessment_reba_percentage" @input="setTextAsInteger('safety_hazard_repetitive_movements_assessment_reba_percentage', $event)">
                    <span>%</span>
                  </label>
                  <div class="extra indented" v-if="1 === surveyData.safety_hazard_repetitive_movements_assessment_reba">
                    <fieldset class="radio-set">
                      <label>
                        <input type="radio" :value="1" :checked="1 === surveyData.safety_hazard_repetitive_movements_assessment_reba_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_reba_how', $event)">
                        <span>Indice calcolato in modo differenziato su lavoratori e lavoratrici</span>
                      </label>
                      <label>
                        <input type="radio" :value="2" :checked="2 === surveyData.safety_hazard_repetitive_movements_assessment_reba_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_reba_how', $event)">
                        <span>Valutazione effettuata sull'osservazione di una popolazione mista di lavoratori e lavoratrici, là dove possibile</span>
                      </label>
                      <label>
                        <input type="radio" :value="3" :checked="3 === surveyData.safety_hazard_repetitive_movements_assessment_reba_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_reba_how', $event)">
                        <span>Non è possibile effettuare una valutazione su un campione misto in quanto è presente un solo genere esposto a movimenti ripetitivi</span>
                      </label>
                      <label>
                        <input type="radio" :value="4" :checked="4 === surveyData.safety_hazard_repetitive_movements_assessment_reba_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_reba_how', $event)">
                        <span>Non è stata effettuata una valutazione basata sull'osservazione di un campione misto</span>
                      </label>
                    </fieldset>
                  </div>
                </div>
                <div class="assessment">
                  <label>
                    <input type="checkbox" :value="1" :checked="1 === surveyData.safety_hazard_repetitive_movements_assessment_plibel" @change="setCheckbox('safety_hazard_repetitive_movements_assessment_plibel', $event)">
                    <span>Metodo PLIBEL</span>
                  </label>
                  <label class="assessment-percentage" v-if="1 === surveyData.safety_hazard_repetitive_movements_assessment_plibel && repetitiveMovementsSelectedAssessments > 1">
                    <input type="number" min="10" :max="100" :step="10" :value="surveyData.safety_hazard_repetitive_movements_assessment_plibel_percentage" @input="setTextAsInteger('safety_hazard_repetitive_movements_assessment_plibel_percentage', $event)">
                    <span>%</span>
                  </label>
                  <div class="extra indented" v-if="1 === surveyData.safety_hazard_repetitive_movements_assessment_plibel">
                    <fieldset class="radio-set">
                      <label>
                        <input type="radio" :value="1" :checked="1 === surveyData.safety_hazard_repetitive_movements_assessment_plibel_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_plibel_how', $event)">
                        <span>Indice calcolato in modo differenziato su lavoratori e lavoratrici</span>
                      </label>
                      <label>
                        <input type="radio" :value="2" :checked="2 === surveyData.safety_hazard_repetitive_movements_assessment_plibel_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_plibel_how', $event)">
                        <span>Valutazione effettuata sull'osservazione di una popolazione mista di lavoratori e lavoratrici, là dove possibile</span>
                      </label>
                      <label>
                        <input type="radio" :value="3" :checked="3 === surveyData.safety_hazard_repetitive_movements_assessment_plibel_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_plibel_how', $event)">
                        <span>Non è possibile effettuare una valutazione su un campione misto in quanto è presente un solo genere esposto a movimenti ripetitivi</span>
                      </label>
                      <label>
                        <input type="radio" :value="4" :checked="4 === surveyData.safety_hazard_repetitive_movements_assessment_plibel_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_plibel_how', $event)">
                        <span>Non è stata effettuata una valutazione basata sull'osservazione di un campione misto</span>
                      </label>
                    </fieldset>
                  </div>
                </div>
                <div class="assessment">
                  <label>
                    <input type="checkbox" :value="1" :checked="1 === surveyData.safety_hazard_repetitive_movements_assessment_tlv" @change="setCheckbox('safety_hazard_repetitive_movements_assessment_tlv', $event)">
                    <span>Metodo TLV ACGH</span>
                  </label>
                  <label class="assessment-percentage" v-if="1 === surveyData.safety_hazard_repetitive_movements_assessment_tlv && repetitiveMovementsSelectedAssessments > 1">
                    <input type="number" min="10" :max="100" :step="10" :value="surveyData.safety_hazard_repetitive_movements_assessment_tlv_percentage" @input="setTextAsInteger('safety_hazard_repetitive_movements_assessment_tlv_percentage', $event)">
                    <span>%</span>
                  </label>
                  <div class="extra indented" v-if="1 === surveyData.safety_hazard_repetitive_movements_assessment_tlv">
                    <fieldset class="radio-set">
                      <label>
                        <input type="radio" :value="1" :checked="1 === surveyData.safety_hazard_repetitive_movements_assessment_tlv_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_tlv_how', $event)">
                        <span>Indice calcolato in modo differenziato su lavoratori e lavoratrici</span>
                      </label>
                      <label>
                        <input type="radio" :value="2" :checked="2 === surveyData.safety_hazard_repetitive_movements_assessment_tlv_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_tlv_how', $event)">
                        <span>Valutazione effettuata sull'osservazione di una popolazione mista (campione rappresentativo) di lavoratori e lavoratrici, là dove possibile</span>
                      </label>
                      <label>
                        <input type="radio" :value="3" :checked="3 === surveyData.safety_hazard_repetitive_movements_assessment_tlv_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_tlv_how', $event)">
                        <span>Non è possibile effettuare una valutazione su un campione misto in quanto è presente un solo genere esposto a movimenti ripetitivi</span>
                      </label>
                      <label>
                        <input type="radio" :value="4" :checked="4 === surveyData.safety_hazard_repetitive_movements_assessment_tlv_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_tlv_how', $event)">
                        <span>Non è stata effettuata una valutazione basata sull'osservazione di un campione misto</span>
                      </label>
                    </fieldset>
                  </div>
                </div>
                <div class="assessment">
                  <label>
                    <input type="checkbox" :value="1" :checked="1 === surveyData.safety_hazard_repetitive_movements_assessment_qec" @change="setCheckbox('safety_hazard_repetitive_movements_assessment_qec', $event)">
                    <span>Metodo QEC</span>
                  </label>
                  <label class="assessment-percentage" v-if="1 === surveyData.safety_hazard_repetitive_movements_assessment_qec && repetitiveMovementsSelectedAssessments > 1">
                    <input type="number" min="10" :max="100" :step="10" :value="surveyData.safety_hazard_repetitive_movements_assessment_qec_percentage" @input="setTextAsInteger('safety_hazard_repetitive_movements_assessment_qec_percentage', $event)">
                    <span>%</span>
                  </label>
                  <div class="extra indented" v-if="1 === surveyData.safety_hazard_repetitive_movements_assessment_qec">
                    <fieldset class="radio-set">
                      <label>
                        <input type="radio" :value="1" :checked="1 === surveyData.safety_hazard_repetitive_movements_assessment_qec_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_qec_how', $event)">
                        <span>Indice calcolato in modo differenziato su lavoratori e lavoratrici</span>
                      </label>
                      <label>
                        <input type="radio" :value="2" :checked="2 === surveyData.safety_hazard_repetitive_movements_assessment_qec_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_qec_how', $event)">
                        <span>Valutazione effettuata sull'osservazione di una popolazione mista (campione rappresentativo) di lavoratori e lavoratrici, là dove possibile</span>
                      </label>
                      <label>
                        <input type="radio" :value="3" :checked="3 === surveyData.safety_hazard_repetitive_movements_assessment_qec_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_qec_how', $event)">
                        <span>Non è possibile effettuare una valutazione su un campione misto in quanto è presente un solo genere esposto a movimenti ripetitivi</span>
                      </label>
                      <label>
                        <input type="radio" :value="4" :checked="4 === surveyData.safety_hazard_repetitive_movements_assessment_qec_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_qec_how', $event)">
                        <span>Non è stata effettuata una valutazione basata sull'osservazione di un campione misto</span>
                      </label>
                    </fieldset>
                  </div>
                </div>
                <div class="assessment">
                  <label>
                    <input type="checkbox" :value="1" :checked="1 === surveyData.safety_hazard_repetitive_movements_assessment_ctd" @change="setCheckbox('safety_hazard_repetitive_movements_assessment_ctd', $event)">
                    <span>Metodo CTD</span>
                  </label>
                  <label class="assessment-percentage" v-if="1 === surveyData.safety_hazard_repetitive_movements_assessment_ctd && repetitiveMovementsSelectedAssessments > 1">
                    <input type="number" min="10" :max="100" :step="10" :value="surveyData.safety_hazard_repetitive_movements_assessment_ctd_percentage" @input="setTextAsInteger('safety_hazard_repetitive_movements_assessment_ctd_percentage', $event)">
                    <span>%</span>
                  </label>
                  <div class="extra indented" v-if="1 === surveyData.safety_hazard_repetitive_movements_assessment_ctd">
                    <fieldset class="radio-set">
                      <label>
                        <input type="radio" :value="1" :checked="1 === surveyData.safety_hazard_repetitive_movements_assessment_ctd_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_ctd_how', $event)">
                        <span>Indice calcolato in modo differenziato su lavoratori e lavoratrici</span>
                      </label>
                      <label>
                        <input type="radio" :value="2" :checked="2 === surveyData.safety_hazard_repetitive_movements_assessment_ctd_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_ctd_how', $event)">
                        <span>Valutazione effettuata sull'osservazione di una popolazione mista (campione rappresentativo) di lavoratori e lavoratrici, là dove possibile</span>
                      </label>
                      <label>
                        <input type="radio" :value="3" :checked="3 === surveyData.safety_hazard_repetitive_movements_assessment_ctd_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_ctd_how', $event)">
                        <span>Non è possibile effettuare una valutazione su un campione misto in quanto è presente un solo genere esposto a movimenti ripetitivi</span>
                      </label>
                      <label>
                        <input type="radio" :value="4" :checked="4 === surveyData.safety_hazard_repetitive_movements_assessment_ctd_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_ctd_how', $event)">
                        <span>Non è stata effettuata una valutazione basata sull'osservazione di un campione misto</span>
                      </label>
                    </fieldset>
                  </div>
                </div>
                <div class="assessment">
                  <label>
                    <input type="checkbox" :value="1" :checked="1 === surveyData.safety_hazard_repetitive_movements_assessment_orege" @change="setCheckbox('safety_hazard_repetitive_movements_assessment_orege', $event)">
                    <span>Metodo OREGE</span>
                  </label>
                  <label class="assessment-percentage" v-if="1 === surveyData.safety_hazard_repetitive_movements_assessment_orege && repetitiveMovementsSelectedAssessments > 1">
                    <input type="number" min="10" :max="100" :step="10" :value="surveyData.safety_hazard_repetitive_movements_assessment_orege_percentage" @input="setTextAsInteger('safety_hazard_repetitive_movements_assessment_orege_percentage', $event)">
                    <span>%</span>
                  </label>
                  <div class="extra indented" v-if="1 === surveyData.safety_hazard_repetitive_movements_assessment_orege">
                    <fieldset class="radio-set">
                      <label>
                        <input type="radio" :value="1" :checked="1 === surveyData.safety_hazard_repetitive_movements_assessment_orege_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_orege_how', $event)">
                        <span>Indice calcolato in modo differenziato su lavoratori e lavoratrici</span>
                      </label>
                      <label>
                        <input type="radio" :value="2" :checked="2 === surveyData.safety_hazard_repetitive_movements_assessment_orege_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_orege_how', $event)">
                        <span>Valutazione effettuata sull'osservazione di una popolazione mista (campione rappresentativo) di lavoratori e lavoratrici, là dove possibile</span>
                      </label>
                      <label>
                        <input type="radio" :value="3" :checked="3 === surveyData.safety_hazard_repetitive_movements_assessment_orege_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_orege_how', $event)">
                        <span>Non è possibile effettuare una valutazione su un campione misto in quanto è presente un solo genere esposto a movimenti ripetitivi</span>
                      </label>
                      <label>
                        <input type="radio" :value="4" :checked="4 === surveyData.safety_hazard_repetitive_movements_assessment_orege_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_orege_how', $event)">
                        <span>Non è stata effettuata una valutazione basata sull'osservazione di un campione misto</span>
                      </label>
                    </fieldset>
                  </div>
                </div>
                <div class="assessment">
                  <label>
                    <input type="checkbox" :value="1" :checked="1 === surveyData.safety_hazard_repetitive_movements_assessment_rogers_muscle_fatigue_analysis" @change="setCheckbox('safety_hazard_repetitive_movements_assessment_rogers_muscle_fatigue_analysis', $event)">
                    <span>Metodo Rogers Muscle Fatigue Analysis</span>
                  </label>
                  <label class="assessment-percentage" v-if="1 === surveyData.safety_hazard_repetitive_movements_assessment_rogers_muscle_fatigue_analysis">
                    <input type="number" min="10" :max="100" :step="10" :value="surveyData.safety_hazard_repetitive_movements_assessment_rogers_muscle_fatigue_analysis_percentage" @input="setTextAsInteger('safety_hazard_repetitive_movements_assessment_rogers_muscle_fatigue_analysis_percentage', $event)">
                    <span>%</span>
                  </label>
                  <div class="extra indented" v-if="1 === surveyData.safety_hazard_repetitive_movements_assessment_rogers_muscle_fatigue_analysis && repetitiveMovementsSelectedAssessments > 1">
                    <fieldset class="radio-set">
                      <label>
                        <input type="radio" :value="1" :checked="1 === surveyData.safety_hazard_repetitive_movements_assessment_rogers_muscle_fatigue_analysis_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_rogers_muscle_fatigue_analysis_how', $event)">
                        <span>Indice calcolato in modo differenziato su lavoratori e lavoratrici</span>
                      </label>
                      <label>
                        <input type="radio" :value="2" :checked="2 === surveyData.safety_hazard_repetitive_movements_assessment_rogers_muscle_fatigue_analysis_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_rogers_muscle_fatigue_analysis_how', $event)">
                        <span>Valutazione effettuata sull'osservazione di una popolazione mista (campione rappresentativo) di lavoratori e lavoratrici, là dove possibile</span>
                      </label>
                      <label>
                        <input type="radio" :value="3" :checked="3 === surveyData.safety_hazard_repetitive_movements_assessment_rogers_muscle_fatigue_analysis_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_rogers_muscle_fatigue_analysis_how', $event)">
                        <span>Non è possibile effettuare una valutazione su un campione misto in quanto è presente un solo genere esposto a movimenti ripetitivi</span>
                      </label>
                      <label>
                        <input type="radio" :value="4" :checked="4 === surveyData.safety_hazard_repetitive_movements_assessment_rogers_muscle_fatigue_analysis_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_rogers_muscle_fatigue_analysis_how', $event)">
                        <span>Non è stata effettuata una valutazione basata sull'osservazione di un campione misto</span>
                      </label>
                    </fieldset>
                  </div>
                </div>
                <div class="assessment">
                  <label>
                    <input type="checkbox" :value="1" :checked="1 === surveyData.safety_hazard_repetitive_movements_assessment_strain_index" @change="setCheckbox('safety_hazard_repetitive_movements_assessment_strain_index', $event)">
                    <span>Metodo Strain Index</span>
                  </label>
                  <label class="assessment-percentage" v-if="1 === surveyData.safety_hazard_repetitive_movements_assessment_strain_index">
                    <input type="number" min="10" :max="100" :step="10" :value="surveyData.safety_hazard_repetitive_movements_assessment_strain_index_percentage" @input="setTextAsInteger('safety_hazard_repetitive_movements_assessment_strain_index_percentage', $event)">
                    <span>%</span>
                  </label>
                  <div class="extra indented" v-if="1 === surveyData.safety_hazard_repetitive_movements_assessment_strain_index && repetitiveMovementsSelectedAssessments > 1">
                    <fieldset class="radio-set">
                      <label>
                        <input type="radio" :value="1" :checked="1 === surveyData.safety_hazard_repetitive_movements_assessment_strain_index_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_strain_index_how', $event)">
                        <span>Indice calcolato in modo differenziato per uomini e donne</span>
                      </label>
                      <label>
                        <input type="radio" :value="2" :checked="2 === surveyData.safety_hazard_repetitive_movements_assessment_strain_index_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_strain_index_how', $event)">
                        <span>Valutazione del fattore forza effettuata tramite somministrazione della Scala di Borg ad un campione rappresentativo di lavoratori e lavoratrici, dove possibile</span>
                      </label>
                      <label>
                        <input type="radio" :value="3" :checked="3 === surveyData.safety_hazard_repetitive_movements_assessment_strain_index_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_strain_index_how', $event)">
                        <span>Non è possibile effettuare una valutazione su un campione misto in quanto è presente un solo genere esposto a movimenti ripetitivi</span>
                      </label>
                      <label>
                        <input type="radio" :value="4" :checked="4 === surveyData.safety_hazard_repetitive_movements_assessment_strain_index_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_strain_index_how', $event)">
                        <span>Non è stata effettuata una valutazione basata sull'osservazione di un campione misto</span>
                      </label>
                    </fieldset>
                  </div>
                </div>
                <div class="assessment">
                  <label>
                    <input type="checkbox" :value="1" :checked="1 === surveyData.safety_hazard_repetitive_movements_assessment_aaws" @change="setCheckbox('safety_hazard_repetitive_movements_assessment_aaws', $event)">
                    <span>Metodo AAWS</span>
                  </label>
                  <label class="assessment-percentage" v-if="1 === surveyData.safety_hazard_repetitive_movements_assessment_aaws && repetitiveMovementsSelectedAssessments > 1">
                    <input type="number" min="10" :max="100" :step="10" :value="surveyData.safety_hazard_repetitive_movements_assessment_aaws_percentage" @input="setTextAsInteger('safety_hazard_repetitive_movements_assessment_aaws_percentage', $event)">
                    <span>%</span>
                  </label>
                  <div class="extra indented" v-if="1 === surveyData.safety_hazard_repetitive_movements_assessment_aaws">
                    <fieldset class="radio-set">
                      <label>
                        <input type="radio" :value="1" :checked="1 === surveyData.safety_hazard_repetitive_movements_assessment_aaws_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_aaws_how', $event)">
                        <span>Indice calcolato in modo differenziato su lavoratori e lavoratrici</span>
                      </label>
                      <label>
                        <input type="radio" :value="2" :checked="2 === surveyData.safety_hazard_repetitive_movements_assessment_aaws_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_aaws_how', $event)">
                        <span>Valutazione effettuata sull'osservazione di una popolazione mista (campione rappresentativo) di lavoratori e lavoratrici, là dove possibile</span>
                      </label>
                      <label>
                        <input type="radio" :value="3" :checked="3 === surveyData.safety_hazard_repetitive_movements_assessment_aaws_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_aaws_how', $event)">
                        <span>Non è possibile effettuare una valutazione su un campione misto in quanto è presente un solo genere esposto a movimenti ripetitivi</span>
                      </label>
                      <label>
                        <input type="radio" :value="4" :checked="4 === surveyData.safety_hazard_repetitive_movements_assessment_aaws_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_aaws_how', $event)">
                        <span>Non è stata effettuata una valutazione basata sull'osservazione di un campione misto</span>
                      </label>
                    </fieldset>
                  </div>
                </div>
                <div class="assessment">
                  <label>
                    <input type="checkbox" :value="1" :checked="1 === surveyData.safety_hazard_repetitive_movements_assessment_eaws" @change="setCheckbox('safety_hazard_repetitive_movements_assessment_eaws', $event)">
                    <span>Metodo EAWS</span>
                  </label>
                  <label class="assessment-percentage" v-if="1 === surveyData.safety_hazard_repetitive_movements_assessment_eaws && repetitiveMovementsSelectedAssessments > 1">
                    <input type="number" min="10" :max="100" :step="10" :value="surveyData.safety_hazard_repetitive_movements_assessment_eaws_percentage" @input="setTextAsInteger('safety_hazard_repetitive_movements_assessment_eaws_percentage', $event)">
                    <span>%</span>
                  </label>
                  <div class="extra indented" v-if="1 === surveyData.safety_hazard_repetitive_movements_assessment_eaws">
                    <fieldset class="radio-set">
                      <label>
                        <input type="radio" :value="1" :checked="1 === surveyData.safety_hazard_repetitive_movements_assessment_eaws_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_eaws_how', $event)">
                        <span>Indice calcolato in modo differenziato su lavoratori e lavoratrici</span>
                      </label>
                      <label>
                        <input type="radio" :value="2" :checked="2 === surveyData.safety_hazard_repetitive_movements_assessment_eaws_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_eaws_how', $event)">
                        <span>Valutazione effettuata sull'osservazione di una popolazione mista (campione rappresentativo) di lavoratori e lavoratrici, là dove possibile</span>
                      </label>
                      <label>
                        <input type="radio" :value="3" :checked="3 === surveyData.safety_hazard_repetitive_movements_assessment_eaws_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_eaws_how', $event)">
                        <span>Non è possibile effettuare una valutazione su un campione misto in quanto è presente un solo genere esposto a movimenti ripetitivi</span>
                      </label>
                      <label>
                        <input type="radio" :value="4" :checked="4 === surveyData.safety_hazard_repetitive_movements_assessment_eaws_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_eaws_how', $event)">
                        <span>Non è stata effettuata una valutazione basata sull'osservazione di un campione misto</span>
                      </label>
                    </fieldset>
                  </div>
                </div>
                <div class="assessment">
                  <label>
                    <input type="checkbox" :value="1" :checked="1 === surveyData.safety_hazard_repetitive_movements_assessment_other" @change="setCheckbox('safety_hazard_repetitive_movements_assessment_other', $event)">
                    <span>Altro</span>
                  </label>
                  <label class="assessment-percentage" v-if="1 === surveyData.safety_hazard_repetitive_movements_assessment_other && repetitiveMovementsSelectedAssessments > 1">
                    <input type="number" min="10" :max="100" :step="10" :value="surveyData.safety_hazard_repetitive_movements_assessment_other_percentage" @input="setTextAsInteger('safety_hazard_repetitive_movements_assessment_other_percentage', $event)">
                    <span>%</span>
                  </label>
                  <div class="extra indented" v-if="1 === surveyData.safety_hazard_repetitive_movements_assessment_other">
                    <label>Specificare: <input type="text" :value="surveyData.safety_hazard_repetitive_movements_assessment_other_what" @input="setText('safety_hazard_repetitive_movements_assessment_other_what', $event)"></label>

                    <fieldset class="radio-set">
                      <label>
                        <input type="radio" :value="1" :checked="1 === surveyData.safety_hazard_repetitive_movements_assessment_other_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_other_how', $event)">
                        <span>Indice calcolato in modo differenziato su lavoratori e lavoratrici</span>
                      </label>
                      <label>
                        <input type="radio" :value="2" :checked="2 === surveyData.safety_hazard_repetitive_movements_assessment_other_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_other_how', $event)">
                        <span>Valutazione effettuata sull'osservazione di una popolazione mista (campione rappresentativo) di lavoratori e lavoratrici, là dove possibile</span>
                      </label>
                      <label>
                        <input type="radio" :value="3" :checked="3 === surveyData.safety_hazard_repetitive_movements_assessment_other_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_other_how', $event)">
                        <span>Non è possibile effettuare una valutazione su un campione misto in quanto è presente un solo genere esposto a movimenti ripetitivi</span>
                      </label>
                      <label>
                        <input type="radio" :value="4" :checked="4 === surveyData.safety_hazard_repetitive_movements_assessment_other_how" @change="setRadio('safety_hazard_repetitive_movements_assessment_other_how', $event)">
                        <span>Non è stata effettuata una valutazione basata sull'osservazione di un campione misto</span>
                      </label>
                    </fieldset>
                  </div>
                </div>
              </fieldset>
            </div>
          </td>
        </tr>
        <tr>
          <td class="hazard">
            <label>
              <input type="checkbox" :value="1" :checked="1 === surveyData.safety_is_hazard_there_work_related_stress" @change="setCheckbox('safety_is_hazard_there_work_related_stress', $event)">
              <span>Stress lavoro correlato</span>
            </label>
          </td>
          <td>
          </td>
        </tr>
        </tbody>
      </table>

      <div class="indented" v-if="1 === surveyData.safety_is_hazard_there_work_related_stress">
          <span class="question-title">
              In relazione al rischio stress lavoro correlato, nell'individuazione del team di valutazione si è tenuto conto di un criterio di genere, in aggiunta a quelli previsti dal metodo stesso, in modo da assicurare una equa o perlomeno rappresentativa distribuzione di genere?
          </span>
        <fieldset class="radio-set">
          <label>
            <input type="radio" :value="1" :checked="1 === surveyData.safety_hazard_work_related_stress_gender_evaluation_team" @change="setRadio('safety_hazard_work_related_stress_gender_evaluation_team', $event)">
            <span>Sì</span>
          </label>
          <label>
            <input type="radio" :value="2" :checked="2 === surveyData.safety_hazard_work_related_stress_gender_evaluation_team" @change="setRadio('safety_hazard_work_related_stress_gender_evaluation_team', $event)">
            <span>No, in quanto sono presenti esclusivamente uomini/donne</span>
          </label>
          <label>
            <input type="radio" :value="0" :checked="0 === surveyData.safety_hazard_work_related_stress_gender_evaluation_team" @change="setRadio('safety_hazard_work_related_stress_gender_evaluation_team', $event)">
            <span>No</span>
          </label>
        </fieldset>
      </div>

      <div class="indented" v-if="1 === surveyData.safety_is_hazard_there_work_related_stress">
        <span class="question-title">
          In relazione al rischio stress lavoro correlato, nell'individuazione dei/lle lavoratori/rici esperti/e nella fase preliminare si è tenuto conto di un criterio di genere, in aggiunta a quelli previsti dal metodo stesso, in modo da assicurare una equa o perlomeno rappresentativa distribuzione di genere?
        </span>
        <fieldset class="radio-set">
          <label>
            <input type="radio" :value="1" :checked="1 === surveyData.safety_hazard_work_related_stress_gender_experienced_worker" @change="setRadio('safety_hazard_work_related_stress_gender_experienced_worker', $event)">
            <span>Sì</span>
          </label>
          <label>
            <input type="radio" :value="2" :checked="2 === surveyData.safety_hazard_work_related_stress_gender_experienced_worker" @change="setRadio('safety_hazard_work_related_stress_gender_experienced_worker', $event)">
            <span>No, in quanto i/le lavoratori/trici esperti/e appartengono ad un singolo genere</span>
          </label>
          <label>
            <input type="radio" :value="0" :checked="0 === surveyData.safety_hazard_work_related_stress_gender_experienced_worker" @change="setRadio('safety_hazard_work_related_stress_gender_experienced_worker', $event)">
            <span>No</span>
          </label>
        </fieldset>
      </div>

      <div class="indented" v-if="1 === surveyData.safety_is_hazard_there_work_related_stress">
        <span class="question-title">
            In relazione al rischio stress lavoro correlato, nella fase di approfondimento è stata prevista la somministrazione di questionari, interviste semistrutturate o focus group ad un campione equamente distribuito per genere o per lo meno rappresentativo del campione?
        </span>
        <fieldset class="radio-set">
          <label>
            <input type="radio" :value="1" :checked="1 === surveyData.safety_hazard_work_related_stress_gender_survey_recipient" @change="setRadio('safety_hazard_work_related_stress_gender_survey_recipient', $event)">
            <span>Sì</span>
          </label>
          <label>
            <input type="radio" :value="2" :checked="2 === surveyData.safety_hazard_work_related_stress_gender_survey_recipient" @change="setRadio('safety_hazard_work_related_stress_gender_survey_recipient', $event)">
            <span>No, in quanto tra i/le lavoratori/trici sono presenti esclusivamente uomini/donne</span>
          </label>
          <label>
            <input type="radio" :value="0" :checked="0 === surveyData.safety_hazard_work_related_stress_gender_survey_recipient" @change="setRadio('safety_hazard_work_related_stress_gender_survey_recipient', $event)">
            <span>No</span>
          </label>
        </fieldset>
      </div>

      <div class="indented" v-if="1 === surveyData.safety_is_hazard_there_work_related_stress">
        <span class="question-title">
            In relazione al rischio stress lavoro correlato, all'interno delle interviste semi-strutturate e/o dei focus group utilizzati nella fase di approfondimento si è tenuto conto di problematiche di genere?
        </span>
        <fieldset class="radio-set">
          <label>
            <input type="radio" :value="1" :checked="1 === surveyData.safety_hazard_work_related_stress_survey_gender_topics" @change="setRadio('safety_hazard_work_related_stress_survey_gender_topics', $event)">
            <span>Sì</span>
          </label>
          <label>
            <input type="radio" :value="0" :checked="0 === surveyData.safety_hazard_work_related_stress_survey_gender_topics" @change="setRadio('safety_hazard_work_related_stress_survey_gender_topics', $event)">
            <span>No</span>
          </label>
        </fieldset>
      </div>

      <div class="indented" v-if="1 === surveyData.safety_is_hazard_there_work_related_stress">
        <span class="question-title">
            In relazione al rischio stress lavoro correlato, è stata effettuata un'analisi dei dati disaggregati per genere nelle varie fasi previste dal metodo di valutazione (fase oggettiva, fase soggettiva)?
        </span>
        <fieldset class="radio-set">
          <label>
            <input type="radio" :value="1" :checked="1 === surveyData.safety_hazard_work_related_stress_gender_analysis" @change="setRadio('safety_hazard_work_related_stress_gender_analysis', $event)">
            <span>Sì</span>
          </label>
          <label>
            <input type="radio" :value="2" :checked="2 === surveyData.safety_hazard_work_related_stress_gender_analysis" @change="setRadio('safety_hazard_work_related_stress_gender_analysis', $event)">
            <span>No, in quanto nel campione è presente un unico genere</span>
          </label>
          <label>
            <input type="radio" :value="0" :checked="0 === surveyData.safety_hazard_work_related_stress_gender_analysis" @change="setRadio('safety_hazard_work_related_stress_gender_analysis', $event)">
            <span>No</span>
          </label>
        </fieldset>
      </div>

      <div class="indented" v-if="1 === surveyData.safety_is_hazard_there_work_related_stress">
        <span class="question-title">
            In relazione al rischio stress lavoro correlato, in caso di positività della fase di approfondimento è stato utilizzato un questionario mirato alla rilevazione di problematiche nell'interfaccia casa-lavoro, aggiuntivo a quanto previsto dal metodo di valutazione stress lavoro correlato applicato?
        </span>
        <fieldset class="radio-set">
          <label>
            <input type="radio" :value="1" :checked="1 === surveyData.safety_hazard_work_related_stress_reconciliation" @change="setRadio('safety_hazard_work_related_stress_reconciliation', $event)">
            <span>Sì</span>
          </label>
          <label>
            <input type="radio" :value="2" :checked="2 === surveyData.safety_hazard_work_related_stress_reconciliation" @change="setRadio('safety_hazard_work_related_stress_reconciliation', $event)">
            <span>No, perché la fase di approfondimento non era necessaria</span>
          </label>
          <label>
            <input type="radio" :value="3" :checked="3 === surveyData.safety_hazard_work_related_stress_reconciliation" @change="setRadio('safety_hazard_work_related_stress_reconciliation', $event)">
            <span>No, perché la fase di approfondimento non è risultata positiva</span>
          </label>
          <label>
            <input type="radio" :value="0" :checked="0 === surveyData.safety_hazard_work_related_stress_reconciliation" @change="setRadio('safety_hazard_work_related_stress_reconciliation', $event)">
            <span>No</span>
          </label>
        </fieldset>
      </div>
    </div>

    <div class="question">
      <span class="question-title">* La portabilità ed efficacia protettiva dei DPI è stata valutata con i lavoratori e le lavoratrici?</span>
      <fieldset class="radio-set">
        <label>
          <input type="radio" :value="1" :checked="1 === surveyData.safety_are_dpi_gender_weighted" @change="setRadio('safety_are_dpi_gender_weighted', $event)">
          <span>Sì</span>
        </label>
        <label>
          <input type="radio" :value="0" :checked="0 === surveyData.safety_are_dpi_gender_weighted" @change="setRadio('safety_are_dpi_gender_weighted', $event)">
          <span>No</span>
        </label>
      </fieldset>

      <div class="indented" v-if="surveyData.safety_are_dpi_gender_weighted === 1">
        <span class="question-title">* Specificare in che modo è stata effettuata la valutazione:</span>
        <fieldset class="checkbox-set">
          <label>
            <input type="checkbox" :value="1" :checked="1 === surveyData.safety_dpi_measure_1" @change="setCheckbox('safety_dpi_measure_1', $event)">
            <span>questionari specifici equamente distribuiti tra lavoratori e lavoratrici</span>
          </label>
          <label>
            <input type="checkbox" :value="1" :checked="1 === surveyData.safety_dpi_measure_2" @change="setCheckbox('safety_dpi_measure_2', $event)">
            <span>focus group</span>
          </label>
          <label>
            <input type="checkbox" :value="1" :checked="1 === surveyData.safety_dpi_measure_3" @change="setCheckbox('safety_dpi_measure_3', $event)">
            <span>dichiarazione del/degli RLS di equa efficacia e portabilità dei DPI</span>
          </label>
          <label>
            <input type="checkbox" :value="1" :checked="1 === surveyData.safety_dpi_measure_other" @change="setCheckbox('safety_dpi_measure_other', $event)">
            <span>altro</span>
            <div class="indented" v-if="1 === surveyData.safety_dpi_measure_other">
              <label>* Specificare: <input type="text" :value="surveyData.safety_dpi_measure_other_what" @input="setText('safety_dpi_measure_other_what', $event)"></label>
            </div>
          </label>
        </fieldset>
      </div>
    </div>

    <div class="question">
      <span class="question-title">* Nella progettazione/organizzazione degli ambienti di lavoro all'interno della vostra azienda\ente si è tenuto conto delle differenze di <span data-explained-by-term @click="showTerm('sesso')">sesso</span> e <span data-explained-by-term @click="showTerm('genere')">genere</span> in conformità ai principi ergonomici?</span>
      <fieldset class="radio-set">
        <label>
          <input type="radio" :value="1" :checked="1 === surveyData.safety_are_work_spaces_gender_projected" @change="setRadio('safety_are_work_spaces_gender_projected', $event)">
          <span>Sì</span>
        </label>
        <label>
          <input type="radio" :value="2" :checked="2 === surveyData.safety_are_work_spaces_gender_projected" @change="setRadio('safety_are_work_spaces_gender_projected', $event)">
          <span>No, perché la progettazione e l'organizzazione degli ambienti di lavoro all'interno della nostra azienda\ente non sono condizionate dalle differenze di sesso e genere</span>
        </label>
        <label>
          <input type="radio" :value="0" :checked="0 === surveyData.safety_are_work_spaces_gender_projected" @change="setRadio('safety_are_work_spaces_gender_projected', $event)">
          <span>No</span>
        </label>
      </fieldset>

      <div class="indented" v-if="surveyData.safety_are_work_spaces_gender_projected === 1">
        <span class="question-title">* Nella progettazione/organizzazione degli ambienti di lavoro all'interno della vostra azienda\ente si è tenuto conto delle differenze di sesso e genere in conformità con i principi ergonomici per le postazioni di lavoro al videoterminale?</span>
        <fieldset class="radio-set">
          <label>
            <input type="radio" :value="1" :checked="1 === surveyData.safety_are_work_spaces_gender_projected_vdt" @change="setRadio('safety_are_work_spaces_gender_projected_vdt', $event)">
            <span>Sì</span>
          </label>
          <label>
            <input type="radio" :value="3" :checked="3 === surveyData.safety_are_work_spaces_gender_projected_vdt" @change="setRadio('safety_are_work_spaces_gender_projected_vdt', $event)">
            <span>No, non sono presenti postazioni di lavoro al videoterminale</span>
          </label>
          <label>
            <input type="radio" :value="2" :checked="2 === surveyData.safety_are_work_spaces_gender_projected_vdt" @change="setRadio('safety_are_work_spaces_gender_projected_vdt', $event)">
            <span>No, perché le differenze di sesso e genere non condizionano la progettazione e l'organizzazione per le postazioni di lavoro al videoterminale</span>
          </label>
          <label>
            <input type="radio" :value="0" :checked="0 === surveyData.safety_are_work_spaces_gender_projected_vdt" @change="setRadio('safety_are_work_spaces_gender_projected_vdt', $event)">
            <span>No</span>
          </label>
        </fieldset>
      </div>

      <div class="indented" v-if="surveyData.safety_are_work_spaces_gender_projected === 1">
        <span class="question-title">* Nella progettazione/organizzazione degli ambienti di lavoro all'interno della vostra azienda\ente si è tenuto conto delle differenze di sesso e genere in conformità con i principi ergonomici per le postazioni di lavoro in unità produttiva (catena, banco di lavoro, etc.)?</span>
        <fieldset class="radio-set">
          <label>
            <input type="radio" :value="1" :checked="1 === surveyData.safety_are_work_spaces_gender_projected_production" @change="setRadio('safety_are_work_spaces_gender_projected_production', $event)">
            <span>Sì</span>
          </label>
          <label>
            <input type="radio" :value="3" :checked="3 === surveyData.safety_are_work_spaces_gender_projected_production" @change="setRadio('safety_are_work_spaces_gender_projected_production', $event)">
            <span>No, non sono presenti unità produttive</span>
          </label>
          <label>
            <input type="radio" :value="2" :checked="2 === surveyData.safety_are_work_spaces_gender_projected_production" @change="setRadio('safety_are_work_spaces_gender_projected_production', $event)">
            <span>No, perché le differenze di sesso e genere non condizionano la progettazione e l'organizzazione per le postazioni di lavoro in unità produttiva</span>
          </label>
          <label>
            <input type="radio" :value="0" :checked="0 === surveyData.safety_are_work_spaces_gender_projected_production" @change="setRadio('safety_are_work_spaces_gender_projected_production', $event)">
            <span>No</span>
          </label>
        </fieldset>
      </div>

      <div class="indented" v-if="surveyData.safety_are_work_spaces_gender_projected === 1">
        <span class="question-title">* Nella progettazione/organizzazione degli ambienti di lavoro all'interno della vostra azienda\ente si è tenuto conto delle differenze di sesso e genere in conformità con i principi ergonomici degli strumenti di lavoro utilizzati (portabilità, adattabilità, etc.)?</span>
        <fieldset class="radio-set">
          <label>
            <input type="radio" :value="1" :checked="1 === surveyData.safety_are_work_spaces_gender_projected_tools" @change="setRadio('safety_are_work_spaces_gender_projected_tools', $event)">
            <span>Sì</span>
          </label>
          <label>
            <input type="radio" :value="3" :checked="3 === surveyData.safety_are_work_spaces_gender_projected_tools" @change="setRadio('safety_are_work_spaces_gender_projected_tools', $event)">
            <span>No, non sono utilizzati strumenti di lavoro</span>
          </label>
          <label>
            <input type="radio" :value="2" :checked="2 === surveyData.safety_are_work_spaces_gender_projected_tools" @change="setRadio('safety_are_work_spaces_gender_projected_tools', $event)">
            <span>No, perché le differenze di sesso e genere non condizionano la progettazione e l'organizzazione per gli strumenti di lavoro utilizzati</span>
          </label>
          <label>
            <input type="radio" :value="0" :checked="0 === surveyData.safety_are_work_spaces_gender_projected_tools" @change="setRadio('safety_are_work_spaces_gender_projected_tools', $event)">
            <span>No</span>
          </label>
        </fieldset>
      </div>
    </div>

    <div class="question">
      <span class="question-title">* Il medico competente, in occasione della relazione annuale, comunica i dati disaggregati per sesso?</span>
      <fieldset class="radio-set">
        <label>
          <input type="radio" :value="1" :checked="1 === surveyData.safety_is_medical_yearly_report_gender_weighted" @change="setRadio('safety_is_medical_yearly_report_gender_weighted', $event)">
          <span>Sì</span>
        </label>
        <label>
          <input type="radio" :value="0" :checked="0 === surveyData.safety_is_medical_yearly_report_gender_weighted" @change="setRadio('safety_is_medical_yearly_report_gender_weighted', $event)">
          <span>No</span>
        </label>
      </fieldset>
    </div>

    <div class="question">
      <span class="question-title">* Il medico competente nella sua attività di Sorveglianza Sanitaria effettua un'anamnesi personale inerente anche gli aspetti di <span data-explained-by-term @click="showTerm('genere')">genere</span>?</span>
      <fieldset class="radio-set">
        <label>
          <input type="radio" :value="2" :checked="2 === surveyData.safety_is_anamnesis_gender_weighted" @change="setRadio('safety_is_anamnesis_gender_weighted', $event)">
          <span>Sì, attraverso uno strumento standardizzato (es. questionario mirato)</span>
        </label>
        <label>
          <input type="radio" :value="1" :checked="1 === surveyData.safety_is_anamnesis_gender_weighted" @change="setRadio('safety_is_anamnesis_gender_weighted', $event)">
          <span>Sì</span>
        </label>
        <label>
          <input type="radio" :value="0" :checked="0 === surveyData.safety_is_anamnesis_gender_weighted" @change="setRadio('safety_is_anamnesis_gender_weighted', $event)">
          <span>No</span>
        </label>
      </fieldset>
    </div>
    <InfoModal v-if="$modals.terms" modal-class="term-modal" :title="term" @close="closeTerm">
      <div v-html="termText"></div>
    </InfoModal>
    <InfoModal v-if="$modals.laws" modal-class="law-modal" :title="law" @close="closeLaw">
      <h2>{{ lawTitle }}</h2>
      <div v-html="lawText"></div>
    </InfoModal>
  </div>
</template>

<script>

import surveyMixin from '@/mixins/surveyMixin.js';
import stepTitleFilter from '@/filters/stepTitleFilter.js';
import InfoModal from '@/libs/Modals/components/InfoModal.vue';

export default {
  mixins: [surveyMixin],
  filters: {
    stepTitle: stepTitleFilter,
  },
  components: {
    InfoModal,
  },
  props: {
    edit: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {};
  },
  computed: {
    manualHandlingOfLoadsSelectedAssessments () {
      let manualHandlingOfLoadsSelectedAssessments = 0;

      if (this.surveyData.safety_hazard_manual_handling_of_loads_assessment_niosh === 1) {
        manualHandlingOfLoadsSelectedAssessments++;
      }

      if (this.surveyData.safety_hazard_manual_handling_of_loads_assessment_owas === 1) {
        manualHandlingOfLoadsSelectedAssessments++;
      }

      if (this.surveyData.safety_hazard_manual_handling_of_loads_assessment_mcg === 1) {
        manualHandlingOfLoadsSelectedAssessments++;
      }

      if (this.surveyData.safety_hazard_manual_handling_of_loads_assessment_mac === 1) {
        manualHandlingOfLoadsSelectedAssessments++;
      }

      if (this.surveyData.safety_hazard_manual_handling_of_loads_assessment_other === 1) {
        manualHandlingOfLoadsSelectedAssessments++;
      }

      return manualHandlingOfLoadsSelectedAssessments;
    },
    pushAndPullingSelectedAssessments () {
      let pushAndPullingSelectedAssessments = 0;

      if (this.surveyData.safety_hazard_pushing_and_pulling_assessment_snook_ciriello === 1) {
        pushAndPullingSelectedAssessments++;
      }

      if (this.surveyData.safety_hazard_pushing_and_pulling_assessment_other === 1) {
        pushAndPullingSelectedAssessments++;
      }

      return pushAndPullingSelectedAssessments;
    },
    manualHandlingOfPatientsSelectedAssessments () {
      let manualHandlingOfPatientsSelectedAssessments = 0;

      if (this.surveyData.safety_hazard_manual_handling_of_patients_assessment_mapo === 1) {
        manualHandlingOfPatientsSelectedAssessments++;
      }

      if (this.surveyData.safety_hazard_manual_handling_of_patients_assessment_sposo === 1) {
        manualHandlingOfPatientsSelectedAssessments++;
      }

      if (this.surveyData.safety_hazard_manual_handling_of_patients_assessment_other === 1) {
        manualHandlingOfPatientsSelectedAssessments++;
      }

      return manualHandlingOfPatientsSelectedAssessments;
    },
    repetitiveMovementsSelectedAssessments () {
      let repetitiveMovementsSelectedAssessments = 0;

      if (this.surveyData.safety_hazard_repetitive_movements_assessment_ocra === 1) {
        repetitiveMovementsSelectedAssessments++;
      }

      if (this.surveyData.safety_hazard_repetitive_movements_assessment_rula === 1) {
        repetitiveMovementsSelectedAssessments++;
      }

      if (this.surveyData.safety_hazard_repetitive_movements_assessment_osha === 1) {
        repetitiveMovementsSelectedAssessments++;
      }

      if (this.surveyData.safety_hazard_repetitive_movements_assessment_reba === 1) {
        repetitiveMovementsSelectedAssessments++;
      }

      if (this.surveyData.safety_hazard_repetitive_movements_assessment_plibel === 1) {
        repetitiveMovementsSelectedAssessments++;
      }

      if (this.surveyData.safety_hazard_repetitive_movements_assessment_tlv === 1) {
        repetitiveMovementsSelectedAssessments++;
      }

      if (this.surveyData.safety_hazard_repetitive_movements_assessment_qec === 1) {
        repetitiveMovementsSelectedAssessments++;
      }

      if (this.surveyData.safety_hazard_repetitive_movements_assessment_ctd === 1) {
        repetitiveMovementsSelectedAssessments++;
      }

      if (this.surveyData.safety_hazard_repetitive_movements_assessment_orege === 1) {
        repetitiveMovementsSelectedAssessments++;
      }

      if (this.surveyData.safety_hazard_repetitive_movements_assessment_rogers_muscle_fatigue_analysis === 1) {
        repetitiveMovementsSelectedAssessments++;
      }

      if (this.surveyData.safety_hazard_repetitive_movements_assessment_strain_index === 1) {
        repetitiveMovementsSelectedAssessments++;
      }

      if (this.surveyData.safety_hazard_repetitive_movements_assessment_aaws === 1) {
        repetitiveMovementsSelectedAssessments++;
      }

      if (this.surveyData.safety_hazard_repetitive_movements_assessment_eaws === 1) {
        repetitiveMovementsSelectedAssessments++;
      }

      if (this.surveyData.safety_hazard_repetitive_movements_assessment_other === 1) {
        repetitiveMovementsSelectedAssessments++;
      }

      return repetitiveMovementsSelectedAssessments;
    },
  },
};

</script>
